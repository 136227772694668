import React from 'react';
import './App.css';
import asynkLogo from './asynk-logo-512.png'

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img id="breathing-text" src={asynkLogo} className="App-logo" alt="logo" />
        <p id="breathing-text"><code>Asynk Await</code></p>
        
        <i>
        <a
          className="App-link"
          href="https://pcoursepro.fr"
          target="_blank"
          rel="noopener noreferrer"
        >
          #PCP
        </a>
        {' '}
        <a
          className="App-link"
          href="https://www.linkedin.com/in/willyfalone/"
          target="_blank"
          rel="noopener noreferrer"
        >
          #HelloUssd
        </a>
        {' '}
        <a
          className="App-link"
          href="https://www.linkedin.com/in/willyfalone/"
          target="_blank"
          rel="noopener noreferrer"
        >
          #Schooling
        </a>
        </i>
      </header>
    </div>
  );
}

export default App;
